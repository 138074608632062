.navigation {
  display: flex;
  gap: 5px;
  justify-content: center;
  font-size: 25px;
  color: black;
  margin: 10px 0;
  flex-wrap: wrap;

  > div {
    transform: scaleX(0.8);
    transition: transform 0.2s ease-in-out;
    &:hover {
      border-bottom: 2px solid #0000ff;
    }
  }
  .current {
    transform: scaleX(0.9);
    padding-bottom: 2px;
    border-bottom: 2px solid #0000ff;
    a {
      color: #0000ff;
    }
  }
  a {
    color: rgb(48, 48, 48);
    &:hover {
      color: #0000ff;
    }
  }
}
