body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #d8f1fc;
  color: rgb(48, 48, 48);
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: #0000ff;
}

.logo-wrapper {
  width: 100%;
  text-align: center;
}

.content-wrapper {
  max-width: 940px;
  margin: 15px auto 20px auto;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;

  align-items: center;
  display: flex;
  flex-direction: column;
}

.bmc-logo {
  max-width: 470px;
  width: 100%;
}

.flex {
  display: flex;
  gap: 10px;
}

.dynamic-content {
  width: 100%;
  max-width: 740px;
  margin: 20px 0;
}

.news-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .news-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    img {
      width: 95px;
    }
    .news-body {
      width: 100%;
      .news-title {
        color: #0000ff;
        font-size: 25px;
      }
      .news-content {
      }
      .news-date {
        text-align: right;
        font-style: italic;
        opacity: 0.5;
      }
    }
  }
}

.about-company-section {
  border-top: 2px solid gray;
  margin-top: 25px;
  padding-top: 25px;
  display: flex;
  gap: 10px;
  img {
    width: 95px;
  }
  .about-company-header {
    color: #0000ff;
    font-size: 25px;
  }
}

.contact-section {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  .contact-section-layout {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    table {
      width: 300px;
    }
  }
  img {
    width: 95px;
  }
  .contact-section-header {
    color: #0000ff;
    font-size: 25px;
  }
}

.timetable-section {
  display: flex;
  flex-direction: column;
  gap: 50px;
  img {
    border: 2px solid #000000;
    width: 100%;
  }
}

.tickets-section {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
  .download-tickets-table {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    a {
      color: #0000ff;
      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
